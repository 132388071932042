
import { defineComponent } from 'vue'
import { isDemoMode, setDemoMode } from '@/utils/demo-mode-flag'
import { showToast } from '@/components/general/Toasts.vue'
import { AuthHelperService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  setup() {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const onLogOut = () => {
      if (!isDemoMode()) { //This should never be called in demo mode, but it's good to check just in case
        auth.logOut()
        router.push('/login')
        showToast("You have successfully logged out")
      }
    }

    const enableDemoMode = () => {
      setDemoMode(true)
      window.location.href = "/"
    }

    const reload = () => window.location.reload()

    return {
      onLogOut,
      isDemoMode: isDemoMode(),
      enableDemoMode,
      reload,
      appVersion: process.env.VUE_APP_VERSION,
      back: () => router.goBackSafely('/', false)
    }
  }
})
