<template>
  <main>
    <teleport to="#title-bar">
      <h1>Menu</h1>
      <button class="button text" @click="back">Back</button>
    </teleport>
    <div class="menu">
      <a class="button text">Account</a>
      <a class="button text">Settings</a>
      <button v-if="!isDemoMode"
              class="button text"
              @click="enableDemoMode">
        Demo Mode
      </button>
      <router-link class="button text" to="/errors">Error Log</router-link>
      <button class="button outline"
              @click="reload">Reload</button>
      <a href="/login"
          v-if="!isDemoMode"
          class="button outline"
          @click.prevent="onLogOut">
        Log Out
      </a>
      <small>version: {{appVersion}}</small>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { isDemoMode, setDemoMode } from '@/utils/demo-mode-flag'
import { showToast } from '@/components/general/Toasts.vue'
import { AuthHelperService, useServiceInjector } from '@/injection'
import { useExtendedRouter } from '@/router/extended-router'

export default defineComponent({
  setup() {
    const router = useExtendedRouter()

    const inject = useServiceInjector()

    const auth = inject(AuthHelperService)

    const onLogOut = () => {
      if (!isDemoMode()) { //This should never be called in demo mode, but it's good to check just in case
        auth.logOut()
        router.push('/login')
        showToast("You have successfully logged out")
      }
    }

    const enableDemoMode = () => {
      setDemoMode(true)
      window.location.href = "/"
    }

    const reload = () => window.location.reload()

    return {
      onLogOut,
      isDemoMode: isDemoMode(),
      enableDemoMode,
      reload,
      appVersion: process.env.VUE_APP_VERSION,
      back: () => router.goBackSafely('/', false)
    }
  }
})
</script>

<style scoped>
.menu {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 64px;
  row-gap: 10px;
}
</style>